// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-block {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 20;
  background: rgba(24, 17, 45, 0.72);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductItemDialog/ProductItemDialog.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;EACX,kCAAkC;EAClC,kCAA0B;UAA1B,0BAA0B;AAC5B","sourcesContent":[".loading-block {\n  position: absolute;\n  display: flex;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  z-index: 20;\n  background: rgba(24, 17, 45, 0.72);\n  backdrop-filter: blur(1px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
